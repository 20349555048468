const API_URL = (process.env as any).VUE_APP_API_URL.replace(/\/$/, '')

export const apiServiceUrls = {
  SERVICE_APPLICATIONS: `${API_URL}/service-applications-dashboards`,
  SERVICE_AUTH: `${API_URL}/service-auth`,
  SERVICE_DATASOURCES: `${API_URL}/service-datasources/v2`,
  SERVICE_DATASOURCES_v1: `${API_URL}/service-datasources`,
  SERVICE_DATA_MODELS: `${API_URL}/service-data-models`,
  SERVICE_ETL_MIDDLEWARE: `${API_URL}/service-etl-middleware`,
  SERVICE_FILTERS: `${API_URL}/service-filters`,
  SERVICE_GEO_DATA: `${API_URL}/service-geo-data`,
  SERVICE_IMAGES: `${API_URL}/service-images`,
  SERVICE_ML_MIDDLEWARE: `${API_URL}/service-ml-middleware`,
  SERVICE_OBJECT_STORAGE: `${API_URL}/service-object-storage`,
  SERVICE_QUERYBUILDER: `${API_URL}/service-querybuilder`,
  SERVICE_REPORT: `${API_URL}/service-report`,
  SERVICE_USER: `${API_URL}/service-user`,
  SERVICE_WIDGETS: `${API_URL}/service-widgets`,
  SERVICE_FILE_MANAGER: `${API_URL}/service-file-manager`,
}

export type ApiService = keyof typeof apiServiceUrls
