import { FileUploader } from '@/shared/api/base-api/types/types'
import { getJtiFromToken } from '@/shared/utils/misc-utils/jwt'
import axios, { AxiosError, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from 'axios'
import { API_TIMEOUT } from '@/app/config/config'
import { ApiService, apiServiceUrls } from '@/shared/api/base-api/urls/serviceUrls'
import { refreshTokens } from '@/shared/api/base-api/decorators/refreshTokens'
import { EmitterEvent, useEventEmitter } from '@/shared/utils/event-emitter/useEventEmitter'

export class ApiError extends Error {
  serverData: any = null
  constructor(message: string, serverData: any = null) {
    super(message)
    this.name = 'ApiError'
    this.serverData = serverData
  }
}

const eventEmitter = useEventEmitter()

export default class BaseApi {
  static successStatuses = [200, 201, 202, 203, 204, 205, 206]

  constructor() {
    axios.defaults.timeout = API_TIMEOUT
  }

  public delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  static getQueryString(query: Record<string, string | number>): string {
    return Object.keys(query)
      .map(key => `${key}=${encodeURIComponent(query[key])}`)
      .join('&')
  }

  static getQueryArrayString(param: string, values: string[]): string {
    return values.map(value => `${param}=${encodeURIComponent(value)}`).join('&')
  }

  @refreshTokens
  protected get<T>(url: string, type: ApiService, options?: AxiosRequestConfig) {
    return axios
      .get<T>(apiServiceUrls[type] + url, options)
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  protected delete<T>(url: string, type: ApiService, options?: AxiosRequestConfig) {
    return axios
      .delete<T>(apiServiceUrls[type] + url, options)
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  protected deleteWithPayload<T1, T2>(url: string, payload: T1, type: ApiService, options?: AxiosRequestConfig) {
    return axios
      .delete<T2, AxiosResponse<T2>>(apiServiceUrls[type] + url, { ...options, data: payload as any })
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  protected post<T1, T2>(url: string, payload: T1, type: ApiService, options?: AxiosRequestConfig): Promise<T2> {
    return axios
      .post<T1, AxiosResponse<T2>>(apiServiceUrls[type] + url, payload, options)
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  protected patch<T1, T2>(url: string, payload: T1, type: ApiService, options?: AxiosRequestConfig): Promise<T2> {
    return axios
      .patch<T1, AxiosResponse<T2>>(apiServiceUrls[type] + url, payload, options)
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  protected put<T1, T2>(url: string, payload: T1, type: ApiService, options?: AxiosRequestConfig): Promise<T2> {
    return axios
      .put<T1, AxiosResponse<T2>>(apiServiceUrls[type] + url, payload, options)
      .then(extractData)
      .catch(processError)
  }

  protected getFileUploader<T>(url: string, formData: FormData, type: ApiService): FileUploader<T> {
    let onProgressFunc: any = null
    let onFailFunc: any = null
    let onDoneFunc: any = null
    let onCancelFunc: any = null

    let isDone = false
    let isCanceled = false
    let isError = false

    const abortController = new AbortController()

    axios
      .post(apiServiceUrls[type] + url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Transfer-Encoding': 'chunked',
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const total = progressEvent.total || 0
          const current = progressEvent.loaded
          const percentage = total > 0 ? Math.round((current / total) * 100) : undefined
          if (onProgressFunc) onProgressFunc({ total, current, percentage })
        },
        timeout: 24 * 3600 * 1000, // 24 hours
        signal: abortController.signal,
      })
      .then((result: AxiosResponse) => {
        console.log('Upload finished')
        isDone = true
        if (onDoneFunc) onDoneFunc(result)
      })
      .catch((error: AxiosError) => {
        isDone = true
        if (axios.isCancel(error)) {
          console.log('Upload canceled')
          isCanceled = true
          if (onCancelFunc) onCancelFunc()
        } else {
          console.error('Error uploading file', error)
          isError = true
          if (onFailFunc) onFailFunc(error)
        }
      })

    const uploader: FileUploader<T> = {
      onDone(func: any) {
        onDoneFunc = func
        return uploader
      },
      onFail(func: any) {
        onFailFunc = func
        return uploader
      },
      onCancel(func: any) {
        onCancelFunc = func
        return uploader
      },
      onProgress(func: any) {
        onProgressFunc = func
        return uploader
      },
      cancel() {
        if (isDone || isCanceled || isError) return
        abortController.abort()
      },
    }

    return uploader
  }

  protected getEventStream(url: string, payload: any, type: ApiService, options: RequestInit = {}) {
    let onDataFunc: any = null
    let onFailFunc: any = null

    let finished = false
    let cancelled = false
    let index = 0

    const controller = new AbortController()
    const signal = controller.signal

    const accessToken = localStorage.getItem('access_token') || ''
    const authHeaders: any = accessToken
      ? {
          Language: localStorage.getItem('lang') || '',
          Realm_guid: localStorage.getItem('realmGuid') || '',
          Session: getJtiFromToken(accessToken),
          Authorization: `Bearer ${accessToken}`,
        }
      : {}

    fetch(apiServiceUrls[type] + url, {
      method: 'POST',
      headers: { Accept: 'text/event-stream', 'Content-Type': 'application/json', ...authHeaders },
      body: JSON.stringify(payload),
      signal: signal,
      ...options,
    })
      .then(response => {
        if (!response.body) {
          throw new Error('ReadableStream not supported by the browser.')
        }

        const reader = response.body.getReader()
        const decoder = new TextDecoder()

        const readData = async () => {
          let buffer = ''

          while (!finished && !cancelled) {
            const { done, value } = await reader.read()

            if (done) {
              console.log('Stream finished')
              finished = true
              onDataFunc({ done: true, value: null })
            } else {
              const chunk = decoder.decode(value, { stream: true })
              const messages = (buffer + chunk).split('\n\n')
              const count = messages.length
              buffer = messages[count - 1]

              console.log(`[${++index}] received message from stream (contains: ${count - 1})`)

              for (let i = 0; i < count - 1; i++) {
                const message = JSON.parse(messages[i].replace(/^data: /, ''))
                onDataFunc({ done: false, value: message })
              }
            }
          }
        }

        readData().catch(error => {
          if (cancelled || finished) return
          console.log('Stream Error:', error.message)
          if (onFailFunc) onFailFunc(error)
        })
      })
      .catch(error => {
        if (cancelled || finished) return
        console.log('Request (Stream) Error:', error.message)
        if (onFailFunc) onFailFunc(error)
      })

    const result = {
      data(callback: any) {
        onDataFunc = callback
        return result
      },
      fail(callback: any) {
        onFailFunc = callback
        return result
      },
      cancel() {
        cancelled = true
        controller.abort()
      },
    }

    return result
  }
}

function extractData<T = any>(response: AxiosResponse<T>) {
  const { data, status } = response
  if (!BaseApi.successStatuses.includes(status)) {
    const errorMessage = (data as any).errorMessage || (data as any).error_message
    if (errorMessage) throw new ApiError(errorMessage, data)
  }
  return data
}

function processError(err: AxiosError): never {
  const data: any = err.response?.data
  const status = err.response?.status

  if (status === 401) {
    eventEmitter.emit(EmitterEvent.JWT_EXPIRED_OR_INVALID)
  }

  let errorMessage = data?.error?.error || data?.error || data?.errorMessage || data?.error_message || err.message
  if (Array.isArray(errorMessage)) errorMessage = JSON.stringify(errorMessage[0])
  throw new ApiError(errorMessage, err.response?.data || null)
}
